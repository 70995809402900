<template>
  <v-card>
    <v-card-title class="px-0 pb-2">
      <v-container class="px-0 py-0 mb-2">
        <v-row align="end" class="px-8">
          <v-col cols="auto">
            <v-btn
              height="29"
              class="label text-capitalize font-weight-regular"
              depressed
              color="secondary"
              :ripple="false"
            >
              <v-icon left>$adminUsers</v-icon>
              {{ $t('page_admin_users_list_title') }}
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="auto">
            <v-btn
              text
              color="primary"
              class="text-capitalize"
              to="/admin-users/create"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('page_admin_users_create_new_text') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text class="px-0 pb-0">
      <Table
        :headers="headers"
        :items="getAdminUsers"
        :search="search"
        :loading="loading"
        :total-records="getAdminUsersPagination.records_total"
        :number-of-pages="getAdminUsersPagination.total_pages"
        @update:options="getDataFromApi"
        @click:row="goToEdit"
      >
        <template v-slot:item.id="{ item }">
          <div class="primary--text">{{ item.id }}</div>
        </template>

        <template v-slot:item.avatar="{ item }">
          <v-avatar color="grey" size="36" class="white--text">
            <v-img :src="item.photo"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.first_name="{ item }">
          <div>{{ item.last_name }} {{ item.first_name }}</div>
        </template>

        <template v-slot:item.status="{ item }">
          {{ item.status === 1 ? 'Active' : 'Inactive' }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn color="secondary" icon :to="`/admin-users/${item.id}/edit`">
            <v-icon size="16">$edit</v-icon>
          </v-btn>
        </template>
      </Table>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { Table },
  created() {
    this.getDataFromApi()
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: this.$t('id'), align: 'center', value: 'id' },
        { text: '', align: 'center', value: 'avatar', sortable: false },
        {
          text: this.$t('page_admin_users_table_header_name'),
          align: 'center',
          value: 'first_name'
        },
        {
          text: this.$t('page_admin_users_table_header_email'),
          align: 'center',
          value: 'email'
        },
        {
          text: this.$t('page_admin_users_table_header_status'),
          align: 'center',
          value: 'status'
        },
        {
          text: this.$t('page_admin_users_table_header_registered'),
          align: 'center',
          value: 'created_at'
        },
        {
          text: this.$t('actions'),
          align: 'center',
          value: 'action',
          sortable: false
        }
      ],
      search: '',
      deleteDialog: false,
      itemId: null
    }
  },
  computed: {
    ...mapGetters(['getAdminUsers', 'getAdminUsersPagination'])
  },
  methods: {
    async getDataFromApi(e) {
      let params = {}
      if (e !== undefined) {
        if (e.sortBy[0] !== undefined) {
          params.order = e.sortDesc[0] ? 'desc' : 'asc'
          params.sort = e.sortBy[0]
        }

        if (e.page !== undefined && e?.itemsPerPage !== undefined) {
          params.page = e.page
          params.paginate = e.itemsPerPage
        }
      }
      this.loading = true
      await this.$store.dispatch('USER_ADMINS_GET', params)
      this.loading = false
    },
    goToEdit(item) {
      this.$router.push({
        name: 'AdminUsersEdit',
        params: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
