var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"px-0 pb-2"},[_c('v-container',{staticClass:"px-0 py-0 mb-2"},[_c('v-row',{staticClass:"px-8",attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"label text-capitalize font-weight-regular",attrs:{"height":"29","depressed":"","color":"secondary","ripple":false}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$adminUsers")]),_vm._v(" "+_vm._s(_vm.$t('page_admin_users_list_title'))+" ")],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"primary","to":"/admin-users/create"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('page_admin_users_create_new_text'))+" ")],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('Table',{attrs:{"headers":_vm.headers,"items":_vm.getAdminUsers,"search":_vm.search,"loading":_vm.loading,"total-records":_vm.getAdminUsersPagination.records_total,"number-of-pages":_vm.getAdminUsersPagination.total_pages},on:{"update:options":_vm.getDataFromApi,"click:row":_vm.goToEdit},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"primary--text"},[_vm._v(_vm._s(item.id))])]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"white--text",attrs:{"color":"grey","size":"36"}},[_c('v-img',{attrs:{"src":item.photo}})],1)]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.last_name)+" "+_vm._s(item.first_name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status === 1 ? 'Active' : 'Inactive')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","icon":"","to":("/admin-users/" + (item.id) + "/edit")}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$edit")])],1)]}}])}),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }